.DCMCloudModal:not(.visibleScrollbar) .DCMCloudModal__content {
  overflow-x: scroll;
  margin-inline: 20px;
  margin: 20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.DCMCloudModal__content {
  padding: 0px !important;
  max-height: 90vh;
  overflow-y: auto;
}


.label-selection, .direction-selection, .exclude-labels {
  margin-bottom: 20px;
  display: flex;
  gap: 20%;
  /* overflow-y: scroll; */
}

.direction-selection, .exclude-labels{
  align-items: center;
}

.label-selection{
overflow-y: scroll;
}


.direction-buttons, .modal-actions {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
}

p{
  margin: 0;
  font-size: medium;
  font-weight: 400;
}



.label-grid {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.label-group {
  display: flex;
  flex-direction: column;
}

.label-button {
  background-color: #3c3c3c;
  color: white;
  border: none;
  padding-inline: 20px;
  padding-block: 10px;
  /* padding: 15px; */
  margin: 2px;
  cursor: pointer;
  border-radius: 7px;
}

.label-button.selected {
  background-color: #1b75f7;
}

.label-button.excluded {
  background-color: #888;
  text-decoration: line-through;
}

.direction-button, .exclude-button, .start-button, .close-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.direction-button {
  background-color: #3c3c3c;
  color: white;
}

.direction-button.selected {
  background-color: #1b75f7;
}

.exclude-button {
  background-color: #3c3c3c;
  color: white;
}

.exclude-button.active {
  background-color: #1b75f7;
}

.start-button {
  background-color: #1b75f7;
  color: white;
}

.close-button {
  background-color: #3c3c3c;
  color: white;
}
