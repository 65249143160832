.gradient-button {
  background-image: linear-gradient(to right, #1062AB, #1DADE4, #1DADE4, #1062AB);
  background-size: 300%;
  background-position: left;
  transition: 300ms background-position ease-in-out;
}

.gradient-button:hover {
  background-position: right;
}
