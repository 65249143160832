/* CUSTOM OHIF SCROLLBAR */
.ohif-scrollbar {
  scrollbar-color: #173239 transparent;
  overflow-y: auto;
}

.ohif-scrollbar-stable-gutter {
  scrollbar-gutter: stable;
}

.study-min-height {
  min-height: 450px;
}

.ohif-scrollbar:hover {
  overflow-y: auto;
}

.ohif-scrollbar::-webkit-scrollbar {
  scrollbar-width: thin;
}

.ohif-scrollbar::-webkit-scrollbar-track {
  @apply rounded;
}

.ohif-scrollbar::-webkit-scrollbar-thumb {
  @apply rounded;
  @apply bg-secondary-dark;
  background-color: #041c4a;
}

.ohif-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  @apply bg-secondary-dark;
  background-color: #041c4a;
}

/* INVISIBLE SCROLLBAR */
.invisible-scrollbar {
  scrollbar-width: none;
}

.invisible-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

.invisible-scrollbar::-webkit-scrollbar-track {
  @apply hidden;
}

.invisible-scrollbar::-webkit-scrollbar-thumb {
  @apply hidden;
}

.invisible-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  @apply hidden;
}

/* PERSONAL SCROLLBAR */

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #8bd0ed;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  box-shadow: 0 0 0 1000px transparent inset;
  -webkit-text-fill-color: white; /* Maintain text color */
}
