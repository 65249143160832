@keyframes tabAnimation {
  0% {
    opacity: 0.5;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-tab {
  animation: tabAnimation 0.4s ease-in-out;
}
