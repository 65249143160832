.formContEmail {
  max-width: 80%;
  margin: 30px auto;
  display: flex;
  flex-direction: row;
  background-color: #01aae6 /*rgb(84, 54, 189)*/;
  padding: 20px 50px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 600px) {
  .contentDiv {
    width: 118% !important;
  }

  .formContEmail {
    max-width: 90%;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    background-color: #01aae6 /*rgb(84, 54, 189)*/;
    padding: 20px 50px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .inputDiv {
    width: 100% !important;
  }

  .mainCont {
    overflow: scroll !important;
    height: 100vh;
  }

  .headerDiv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 279px;
  }
}

@media only screen and (max-width: 600px) {
  .close {
    top: 4px !important;
    font-size: 24px !important;
  }
}

@media only screen and (max-width: 400px) {
  .contentDiv {
    width: 118% !important;
  }

  .formContEmail {
    max-width: 80%;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    background-color: #01aae6 /*rgb(84, 54, 189)*/;
    padding: 20px 50px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .inputDiv {
    width: 100% !important;
  }

  .mainCont {
    overflow: scroll !important;
    height: 100vh;
  }

  .headerDiv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 588px;
  }

  .botss {
    transform: scale(0.85);
    transform-origin: 0px 0px;
    margin-left: 44px !important;
  }
}

@media only screen and (max-width: 320px) {
  .contentDiv {
    width: 118% !important;
  }

  .formContEmail {
    max-width: 90%;
    /* margin: 30px auto; */
    display: flex;
    flex-direction: column;
    background-color: #01aae6 /*rgb(84, 54, 189)*/;
    /* padding: 20px 20px; */
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .inputDiv {
    width: 100% !important;
  }

  .mainCont {
    overflow: scroll !important;
    height: 99%;
  }

  .headerDiv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 720px;
  }

  .botss {
    height: 74px !important;
    width: 251px !important;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 1050px) {
}

.form-header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.form-header-inner {
  font-size: 24px;
  font-weight: 400;
  color: white;
}

.formPara {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px;
  font-weight: 300;
  color: white;
}

.disclaimer {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  place-content: center;
  place-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  color: gray;
  margin-bottom: 20px;
}

.svgImage {
  width: 20px;
  height: 20px;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: white;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: black;
}

input::placeholder {
  color: gray;
}

#sendInfo {
  width: auto;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.checkbox-group label {
  margin-bottom: 5px;
  font-weight: normal;
}

.checkBoxGroup {
  display: flex;
  flex-direction: row;
  gap: 10px;
  place-items: center;
  place-content: center;
}

.checkbox-group input {
  margin-right: 5px;
}

.btn-primary {
  background-color: #003366;
  color: white;
  padding: 10px 20px;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
}

.btnSubmit {
  display: flex;
  place-items: center;
  gap: 4px;
  margin-left: -3rem;
}

.btn-primary:hover {
  background-color: #123f6b;
}

.mainCont {
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
}

.optional-checkbox {
  margin-top: 10px;
  font-size: 12px;
}

.headerDiv {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contentDiv {
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: space-between;
}

.contentDivGroup {
  display: flex;
  gap: 1rem;
  width: 75%;
  flex-direction: column;
  height: 80%;
}

.inputDivGroup {
  height: 80%;
}

.inputDiv {
  display: flex;
  width: 60%;
  justify-content: space-between;
  flex-direction: column;
}

.formGroupBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  place-items: center;
}

.form-group input[type='text']::placeholder {
  color: gray !important;
}

.form-group input[type='email']::placeholder {
  color: gray !important;
}

.links {
  display: flex;
  height: 2.7rem;
  flex-direction: row;
  gap: 0.3rem;
  place-items: center;
}

.checkBoxGroup label {
  font-size: 12px;
}

#link:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .formGroupBtn {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    place-items: center;
  }

  .MuiButton-textSizeLarge {
    padding: 8px 25px !important;
    font-size: 0.9375rem !important;
  }
  .mainCont {
    overflow: scroll !important;
    height: 100vh;
  }
}

#overlayout {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  z-index: 99999;

  /* display: none; */
  /* min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden; */
}

.modalBucket {
  max-width: 346px;
  margin: 15% auto;
  width: 399px;
  min-height: 157px;
  background: rgba(172, 168, 168, 0.15);
  /* background: hsla(0, 0%, 100%, .35); */
  box-shadow: 0 8px 32px 0 rgba(121, 122, 138, 0.37);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  -webkit-transform: translateY(0);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 1rem;
  padding: 1.5rem;
  z-index: 10;
  color: whitesmoke;
}

.heading {
  font-size: 2.2rem;
  margin-bottom: 1rem;
  color: #01aae6;
  text-align: center;
  font-weight: 700;
}

.subheading {
  font-size: 2.2rem;
  margin-bottom: 1rem;
  color: whitesmoke;
  text-align: center;
  font-weight: 700;
}

.close {
  position: absolute;
  top: 2px;
  right: 14px;
  font-size: 30px;
  cursor: pointer;
  z-index: 5;
}
