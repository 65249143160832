.customSelectDark__wrapper .customSelectDark__control {
  @apply border-primary-gray dark:border-primary-newgray dark:bg-primary-newgray w-full rounded border bg-white text-sm leading-tight text-black shadow transition duration-300 dark:text-white;
  min-height: 28px;
}
.customSelectDark__wrapper .customSelectDark__control:hover {
  @apply border-gray-500;
}

.customSelectDark__control {
  height: 32px;
}

.customSelectDark__value-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.customSelectDark__input {
  @apply text-black dark:text-white !important;
  font-size: 0.75rem !important;
  line-height: 1.25 !important;
}

.customSelectDark__wrapper .customSelectDark__control:focus,
.customSelectDark__wrapper .customSelectDark__control--menu-is-open {
  @apply border-gray-500 outline-none;
}

.customSelectDark--is-disabled .customSelectDark__control--is-disabled {
  @apply pointer-events-none;
}

.css-1hwfws3 {
  padding: 0;
}

.customSelectDark__wrapper .customSelectDark__indicator-separator {
  @apply hidden;
}

.customSelectDark__wrapper .customSelectDark__dropdown-indicator {
  padding: 4px;
}

.customSelectDark__option {
  color: #fff !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  @apply flex flex-row items-center !important;
}

.customSelectDark__option--is-selected {
  @apply bg-transparent !important;
}

.customSelectDark__wrapper .customSelectDark__single-value {
  @apply pl-1 text-black dark:text-white;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.customSelectDark__wrapper.customSelectDark--is-disabled {
  @apply pointer-events-auto cursor-not-allowed;
}

.customSelectDark__wrapper .customSelectDark__value-container--is-multi {
  @apply inline-block truncate px-3 py-2;
}

.customSelectDark__menu-portal .customSelectDark__menu {
  background-color: transparent !important;
  border-radius: 6px;
}

.customSelectDark__menu-portal .customSelectDark__menu-list {
  overflow-x: hidden !important;
  margin: 4px;
  padding-top: 0px !important;
}

.customSelectDark__menu-portal .customSelectDark__menu-list::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}
.customSelectDark__menu-portal .customSelectDark__placeholder {
  @apply pl-1.5 text-black dark:text-white;
}

.customSelectDark__menu-portal .customSelectDark__menu-list::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  border-radius: 10px;
}

.customSelectDark__option--is-focused {
  background-color: #292f36 !important;
  border-radius: 7px;
}

.customSelectDark__menu-portal .customSelectDark__option:hover {
  background-color: #292f36;
  border-radius: 7px;
}

.customSelectDark__menu-portal .customSelectDark__menu {
  border: 2px solid #c0c0c0;
  border-radius: 6px;
  background-color: #151c24 !important;
}

.css-1hwfws3 {
  all: unset !important;
}
