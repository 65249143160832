body {
  background: transparent;
  overflow: hidden !important;

}

.mywraper {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.studyloader-wrap {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.loader {
  /* position: fixed; */
  /* top: 45%; */
  /* left: 45%; */
  border: 4px solid #0181ff;
  border-top: 4px solid transparent;
  /* Blue */
  box-sizing: border-box;
  /* Light grey */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  z-index: 100;
}

.mydiv {
  position: absolute;
  /* top: 45%; */
  /* left: 45%; */
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* background: url(../assets/settings.png); */
  animation: respin 2s linear infinite;
}

.imgdiv {
  width: 152%;
  height: 100%;
  /* color: aqua; */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes respin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/* @-webkit-keyframes respin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
} */

/* ======== Layout Max Resolution =========== */
@media only screen and (max-width: 4000px) and (min-width: 3000px) {
  .loader {
    border: 10px solid #0181ff;
    border-top: 10px solid transparent;
    box-sizing: border-box;
    border-radius: 50%;
    width: 360px;
    height: 360px;
    animation: spin 2s linear infinite;
    z-index: 100;
  }

  .mydiv {
    width: 300px;
    height: 326px;
  }

  .imgdiv {
    width: 152%;
    height: 100%;
  }
}

@media only screen and (max-width: 3001px) and (min-width: 2000px) {
  .loader {
    border: 7px solid #0181ff;
    border-top: 7px solid transparent;
    box-sizing: border-box;
    border-radius: 50%;
    width: 280px;
    height: 280px;
    animation: spin 2s linear infinite;
    z-index: 100;
  }

  .mydiv {
    width: 251px;
    height: 242px;
  }

  .imgdiv {
    width: 152%;
    height: 100%;
  }
}

@media only screen and (max-width: 2001px) and (min-width: 1600px) {
  .loader {
    border: 5px solid #0181ff;
    border-top: 5px solid transparent;
    box-sizing: border-box;
    border-radius: 50%;
    width: 220px;
    height: 220px;
    animation: spin 2s linear infinite;
    z-index: 100;
  }

  .mydiv {
    width: 186px;
    height: 192px;
  }

  .imgdiv {
    width: 152%;
    height: 100%;
  }
}
